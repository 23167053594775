#watch-flex
{
    display: flex;
    gap: 20px 80px;
    flex-wrap: wrap;
}

.watch-physical
{
    width: 220px;
    white-space: pre-wrap;
    line-height: 1.5em;
}

.watch-physical img
{
    margin-top: 16px;
    width: 100%;
    height: auto;
    margin-bottom: 20px;
}

.watch-screenings
{
    line-height: 1.5em;
    width: calc(100% - 600px);
}

.watch-screenings h2
{
    margin-bottom: 16px;
}

.watch-screening
{
    padding: 16px 32px;
    background: #be8f1f;
    border-radius: 8px;
    margin-bottom: 4px;
    display: flex;
}

.watch-screening-date
{
    margin: 0 30px 0 0;
    font-size: 20rem;
    min-width: 110px;
}

.app.ar .watch-screening-date
{
    margin: 0 0 0 30px;
}

@media only screen and (max-width: 1300px) {
    .watch-screenings
    {
        flex-basis: 100%;
        margin-top: 40px;
    }
}

@media only screen and (max-width: 750px) {
    .watch-physical
    {
        flex-basis: 100%;
        width: 100%;
        margin-bottom: 40px;
    }

    .watch-screenings
    {
        margin-top: 0;
    }
}