#synopsis-credits
{
    color: var(--textYellow);
    font-size: 13rem;
    line-height: 1.6em;
    font-style: italic;
    white-space: pre-wrap;
}

#synopsis-text
{
    white-space: pre-wrap;
}