#home
{
    padding-top: 0;
    background-color: #000000;
    min-height: 100vh;
    text-align: center;
}

#logo
{
    width: 600px;
    margin-top: calc(50px + 15vh);
    margin-bottom: 5vh;
    max-width: 100%;
}

#logos
{
    margin-top: 5vh;
    margin-bottom: -60px;
    max-width: 100%;
}

#laurels
{
    margin-top: 5vh;
    max-width: 100%;
}

.creditTitle
{
    font-family: trajan-sans-pro, sans-serif;
    color: var(--textBright);
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.45em;
    font-size: 16rem;
    line-height: 1.2em;
}

.creditName
{
    font-family: trajan-sans-pro, sans-serif;
    color: var(--textYellow);
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.27em;
    font-size: 18rem;
    line-height: 1.2em;
}

.app.ar .creditName
{
    font-size: 15rem;
}

.app.ar .creditName, .app.ar .creditTitle
{
    letter-spacing: normal;
}