@import-normalize; /* bring in normalize.css styles */
@import url("https://use.typekit.net/haq5yis.css"); /* regular 400 / semibold 600 */
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,400;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Amiri:ital,wght@0,400;0,700;1,400&display=swap');

/* Set padding to be subtracted from height/width instead of added to it */
* { 
    -moz-box-sizing: border-box; 
    -webkit-box-sizing: border-box; 
     box-sizing: border-box; 
}

:root {
    --textYellow: #e4ac23;
    --textNormal: #999999;
    --textBright: #ffffff;
}

html
{
    font-size: 0.9px;
}

html.ar
{
    font-size: 1.1px;
    direction: rtl;
}

.app
{
    width: auto;
    font-family: 'Merriweather', serif;
    font-weight: 400;
    font-style: normal;
    font-size: 15rem;
    line-height: 2em;
    color: var(--textNormal);
}

.app.ar
{
    font-family: 'Amiri', serif;
}

.app.ar h1, .app.ar h2, .app.ar button, .app.ar .button
{
    letter-spacing: normal;
}

h1
{
    font-family: trajan-sans-pro, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 40rem;
    text-transform: uppercase;
    color: var(--textBright);
    letter-spacing: .27em;
    margin-bottom: 50px;
}

h2
{
    font-family: trajan-sans-pro, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 20rem;
    text-transform: uppercase;
    color: var(--textBright);
    letter-spacing: .27em;
    margin-bottom: 0;
}

p
{
    margin: 0;
}

a
{
    color: inherit;
    text-decoration: none;
}

button
{
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    font-family: trajan-sans-pro, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 15rem;
    text-transform: uppercase;
    color: var(--textBright);
    letter-spacing: .27em;
}

.button
{
    clear: both;
    display: block;
    width: fit-content;
    background: #000000;
    border: 0;
    border-radius: 12px;
    padding: 10px 20px;
    font-family: trajan-sans-pro, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 12rem;
    text-transform: uppercase;
    color: var(--textBright);
    letter-spacing: .27em;
    margin-top: 30rem;
    line-height: 2em;
    cursor: pointer;
}

.button.yellow
{
    background: var(--textYellow);
}

.page
{
    min-height: 100vh;
    padding: 100px 0 160px 0;
}

.page.yellow
{
    background: url(./../img/bkg-yellow.png) top left repeat;
    background-size: 150px;
    color: var(--textBright);
}

.page.black
{
    background: url(./../img/bkg-black.png) top left repeat;
    background-size: 150px;
}

.content
{
    width: 1200px;
    margin: 0 auto;
}

@media only screen and (max-width: 1500px) {
    .content
    {
        width: 100vw;
        padding: 0 10vw;
    }
}