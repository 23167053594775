.trailer
{
    position: relative;
    float: left;
}

.app.ar .trailer
{
    float: right;
}

.trailer iframe
{
    width: 800px;
    height: 450px;
}

.trailer-text
{
    float: left;
    padding: 0 0 0 40px;
    width: calc(100% - 800px);
    white-space: pre-wrap;
}

.app.ar .trailer-text
{
    float: right;
    padding: 0 40px 0 0;
}


@media only screen and (max-width: 1300px) {
    
    .trailer, .app.ar .trailer
    {
        float: none;
    }
    
    .trailer iframe
    {
        width: 80vw;
        height: calc(80vw / 16 * 9);
    }

    .trailer-text, .app.ar .trailer-text
    {
        float: none;
        padding: 0;
        margin-top: 30px;
        width: 100%;
    }
}