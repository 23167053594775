:root 
{
    --gap: 16px;
}

#gallery h1
{
    float: left;
}

.app.ar #gallery h1
{
    float: right;
}

.gallery-menu
{
    float: right;
    margin-top: 34px;
}

.app.ar .gallery-menu
{
    float: left;
    margin-top: 42px;
}

.gallery-button
{
    margin: 0 0 0 20px;
}

.app.ar .gallery-button
{
    margin: 0 20px 0 0;
}

.gallery-button.active
{
    color: var(--textYellow);
}

.gallery-flex
{
    clear: both;
    width: calc(100% + var(--gap));
    display: none;
}

.gallery-flex.active
{
    display: block;
}

.gallery-flex:last-child:after, .gallery-flex::after
{
    content: "";
    clear: both;
    display: table;
}

.gallery-flex img
{
    display: block;
    width: 100%;
    height: auto;
}

.gallery-flex div
{ 
    float: left;
    margin: 0 var(--gap) var(--gap) 0;
    width: 288px;
}

.app.ar .gallery-flex div
{ 
    float: right;
    margin: 0 0 var(--gap) var(--gap);
}

@media only screen and (max-width: 1500px) {
    .gallery-flex div
    { 
        width: calc((80vw - (3 * var(--gap))) / 4);
    }
}

@media only screen and (max-width: 1300px) {
    .gallery-flex div
    { 
        width: calc((80vw - (2 * var(--gap))) / 3);
    }

    #gallery h1, .app.ar #gallery h1
    {
        float: none;
    }

    .gallery-menu, .app.ar .gallery-menu
    {
        float: none;
        margin-top: 0px;
        padding-bottom: 20px;
    }

    .gallery-button, .app.ar .gallery-button
    {
        display: block;
        margin: 0 0 10px;
    }
}

@media only screen and (max-width: 1000px) {
    .gallery-flex div
    { 
        width: calc((80vw - (1 * var(--gap))) / 2);
    }
}

@media only screen and (max-width: 700px) {
    .gallery-flex div
    { 
        width: 100%;
    }
}