#credits
{
    text-align: center;
}

.credits-list
{
    text-transform: uppercase;
    line-height: 20rem;
    height: calc(23 * 20rem);
    overflow-y: hidden;
    margin-bottom: 40px;
}

.credits-list h1
{
    color:#7a5915;
    font-size: 13rem;
    line-height: inherit;
    letter-spacing: normal;
    margin: 0;
    padding: 0;
}

.credits-list p
{
    margin-bottom: 20rem;
    white-space: pre-wrap;
}

.credits-list.full
{
    height: auto;
    overflow-y: auto;
}

.credits-logos
{
    margin: 60px 0;
}

.credits-logos img
{
    max-width: 100%;
}

#credits .button
{
    margin-left: auto;
    margin-right: auto;
}